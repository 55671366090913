<template>
    <span>
      <h1 v-if="heading && heading.length > 0">{{ heading }}</h1>
      <b-nav class="pt-0 breadcrumb-container d-none d-sm-block d-lg-inline-block">
          <b-breadcrumb :items="items"/>
      </b-nav>
    </span>
</template>

<script>
export default {
  props: ['heading'],
  data () {
    return {
      items: []
    }
  },
  methods: {
    getUrl (path, sub, index) {
      var pathToGo = '/' + path.split(sub)[0] + sub;
      if (pathToGo === '/app') {
        pathToGo = '/';
      }
      return pathToGo;
    }
  },
  mounted () {
    let path = this.$route.path.substr(1);
    let rawPaths = path.split('/');

    var prevSub;
    
    rawPaths.map((sub, index) => {

      var text;

      if (!isNaN(sub)) { 
        text = this.$t('common.edit');
      } else if (typeof this.$t('menu.' + sub) === 'object') {
        text = this.$t('menu.' + sub + '.index');
      } else {
        if (prevSub && typeof this.$t('menu.' + prevSub) === 'object') {
          text = this.$t('menu.' + prevSub + '.' + sub);
        }
      }

      if (sub == 'app') {
        this.items.push({
          html: '<i class="simple-icon-home"></i>',
          to: this.getUrl(path, sub, index)
        });
      } else {
        this.items.push({
          text: (rawPaths.length - 1 == index && this.heading) ? this.heading : text,
          to: this.getUrl(path, sub, index)
        });
      }

      if (sub != 'app') {
        prevSub = sub;
      }
      

    });

  }
}
</script>
