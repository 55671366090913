export const apiUrl = process.env.NODE_ENV == 'production' ? 'https://staff.tehexpert.com.ua/api' : 'http://staff.tehexpert.local/api';
export const defaultMenuType = 'menu-default'; // 'menu-default', 'menu-sub-hidden', 'menu-hidden'
export const searchPath = '#';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;

export const defaultLocale = 'ru';
export const defaultDirection = 'ltr';
export const localeOptions = [
  { id: 'uk', name: 'Українська', direction: 'ltr' },
];

export const defaultColor = 'light.orange';
export const colors = [
  'light.purple',
  'dark.purple',
  'light.blue',
  'dark.blue',
  'light.green',
  'dark.green',
  'light.orange',
  'dark.orange',
  'light.red',
  'dark.red'
];
