import Vue from "vue";
import VueRouter from "vue-router";
import store from './store';
import AuthRequired from './utils/AuthRequired';

Vue.use(VueRouter);

const routes = [
  {
    path: '/user',
    component: () => import('./views/user'),
    redirect: '/user/login',
    children: [
      {
        path: 'login',
        component: () => import('./views/user/login'),
        name: 'login',
      },
    ]
  },
  {
      path: '/',
      beforeEnter: AuthRequired,
      redirect: '/app/staff',
      name: 'home',
      component: () => import('./views/app'),
      children: [
        {
          path: 'app/staff',
          component: () => import('./views/app/staff/index'),
          name: 'staff.index',
        },
        { 
          path: 'app/staff/create',
          component: () => import('./views/app/staff/edit'),
          name: 'staff.create'
        },
        { 
          path: 'app/staff/:id',
          component: () => import('./views/app/staff/edit'),
          name: 'staff.edit'
        },
        {
          path: 'app/jobs',
          redirect: 'app/jobs/staff-lists',
        },
        {
          path: 'app/jobs/staff-lists',
          component: () => import('./views/app/jobs/staff-lists/index'),
          name: 'jobs.staff-lists.index',
        },
        {
          path: 'app/jobs/staff-lists/create',
          component: () => import('./views/app/jobs/staff-lists/edit'),
          name: 'jobs.staff-lists.create',
        },
        {
          path: 'app/jobs/staff-lists/:id',
          component: () => import('./views/app/jobs/staff-lists/edit'),
          name: 'jobs.staff-lists.edit',
        },
        {
          path: 'app/jobs/times',
          component: () => import('./views/app/jobs/times/index'),
          name: 'jobs.times.index',
        },
        {
          path: 'app/jobs/times/create',
          component: () => import('./views/app/jobs/times/edit'),
          name: 'jobs.times.create',
        },
        {
          path: 'app/jobs/times/:id',
          component: () => import('./views/app/jobs/times/edit'),
          name: 'jobs.times.edit',
        },
        {
          path: 'app/documents',
          redirect: 'app/documents/hires',
        },
        {
          path: 'app/documents/hires',
          component: () => import('./views/app/documents/hires/index'),
          name: 'documents.hires.index',
        },
        {
          path: 'app/documents/hires/create',
          component: () => import('./views/app/documents/hires/edit'),
          name: 'documents.hires.create',
        },
        {
          path: 'app/documents/hires/:id',
          component: () => import('./views/app/documents/hires/edit'),
          name: 'documents.hires.edit',
        },
        {
          path: 'app/documents/moves',
          component: () => import('./views/app/documents/moves/index'),
          name: 'documents.moves.index',
        },
        {
          path: 'app/documents/moves/create',
          component: () => import('./views/app/documents/moves/edit'),
          name: 'documents.moves.create',
        },
        {
          path: 'app/documents/moves/:id',
          component: () => import('./views/app/documents/moves/edit'),
          name: 'documents.moves.edit',
        },
        {
          path: 'app/documents/fires',
          component: () => import('./views/app/documents/fires/index'),
          name: 'documents.fires.index',
        },
        {
          path: 'app/documents/fires/create',
          component: () => import('./views/app/documents/fires/edit'),
          name: 'documents.fires.create',
        },
        {
          path: 'app/documents/fires/:id',
          component: () => import('./views/app/documents/fires/edit'),
          name: 'documents.fires.edit',
        },
        {
          path: 'app/documents/contracts',
          component: () => import('./views/app/documents/contracts/index'),
          name: 'documents.contracts.index',
        },
        {
          path: 'app/documents/contracts/create',
          component: () => import('./views/app/documents/contracts/edit'),
          name: 'documents.contracts.create',
        },
        {
          path: 'app/documents/contracts/:id',
          component: () => import('./views/app/documents/contracts/edit'),
          name: 'documents.contracts.edit',
        },
        {
          path: 'app/documents/books',
          component: () => import('./views/app/documents/books/index'),
          name: 'documents.books.index',
        },
        {
          path: 'app/documents/books/create',
          component: () => import('./views/app/documents/books/edit'),
          name: 'documents.books.create',
        },
        {
          path: 'app/documents/books/:id',
          component: () => import('./views/app/documents/books/edit'),
          name: 'documents.books.edit',
        },
        {
          path: 'app/absences',
          redirect: 'app/absences/vacations',
        },
        {
          path: 'app/absences/vacations',
          component: () => import('./views/app/absences/vacations/index'),
          name: 'absences.vacations.index',
        },
        {
          path: 'app/absences/vacations/create',
          component: () => import('./views/app/absences/vacations/edit'),
          name: 'absences.vacations.create',
        },
        {
          path: 'app/absences/vacations/:id',
          component: () => import('./views/app/absences/vacations/edit'),
          name: 'absences.vacations.edit',
        },
        {
          path: 'app/absences/trips',
          component: () => import('./views/app/absences/trips/index'),
          name: 'absences.trips.index',
        },
        {
          path: 'app/absences/trips/create',
          component: () => import('./views/app/absences/trips/edit'),
          name: 'absences.trips.create',
        },
        {
          path: 'app/absences/trips/:id',
          component: () => import('./views/app/absences/trips/edit'),
          name: 'absences.trips.edit',
        },
        {
          path: 'app/absences/sicks',
          component: () => import('./views/app/absences/sicks/index'),
          name: 'absences.sicks.index',
        },
        {
          path: 'app/absences/sicks/create',
          component: () => import('./views/app/absences/sicks/edit'),
          name: 'absences.sicks.create',
        },
        {
          path: 'app/absences/sicks/:id',
          component: () => import('./views/app/absences/sicks/edit'),
          name: 'absences.sicks.edit',
        },
        {
          path: 'app/files',
          component: () => import('./views/app/files/index'),
          name: 'files.index',
        },
        {
          path: 'app/files/create',
          component: () => import('./views/app/files/edit'),
          name: 'files.create',
        },
        {
          path: 'app/files/:id',
          component: () => import('./views/app/files/edit'),
          name: 'files.edit',
        },
        {
          path: 'app/enum',
          redirect: 'app/enum/country',
        },
        { 
          path: 'app/enum/:enum',
          component: () => import('./views/app/enum/index'),
          name: 'enum.index'
        },
        { 
          path: 'app/settings',
          redirect: 'app/settings/constants',
        },
        {
          path: 'app/settings/constants',
          component: () => import('./views/app/settings/constants'),
          name: 'settings.constants',
        },
        {
          path: 'app/settings/users',
          component: () => import('./views/app/settings/users/index'),
          name: 'settings.users.index',
        },
        { 
          path: 'app/settings/users/create',
          component: () => import('./views/app/settings/users/edit'),
          name: 'settings.users.create'
        },
        { 
          path: 'app/settings/users/:id',
          component: () => import('./views/app/settings/users/edit'),
          name: 'settings.users.edit'
        },
        {
          path: 'app/settings/backups',
          component: () => import('./views/app/settings/backups'),
          name: 'settings.backups',
        },
      ]
  }
];

const router = new VueRouter({
  linkActiveClass: 'active',
  routes,
  mode: 'history'
});

router.beforeEach((to, from, next) => {
  store.commit('setAlert', null);
  next();
});

export default router;
