import app from '../../app';

export default {
  state: {
    currentUser: localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')) : null,
    loginError: null,
    processing: false,
  },
  getters: {
    currentUser: state => state.currentUser,
    processing: state => state.processing,
    loginError: state => state.loginError,
  },
  mutations: {
    setUser(state, payload) {
      state.currentUser = payload;
      state.processing = false;
      state.loginError = null;
    },
    setLogout(state) {
      state.currentUser = null;
      state.processing = false;
      state.loginError = null;
    },
    setProcessing(state, payload) {
      state.processing = payload;
      state.loginError = null;
    },
    setError(state, payload) {
      state.loginError = payload;
      state.currentUser = null;
      state.processing = false;
    },
    clearError(state) {
      state.loginError = null;
    }
  },
  actions: {
    login({ commit }, payload) {
      commit('clearError');
      commit('setProcessing', true);
      app.$http
          .post('/login', payload)
          .then(response => {
            commit('setProcessing', false);
            const user = response.data;            
            localStorage.setItem('user', JSON.stringify(user));
            app.$http.defaults.headers.Authorization = 'Bearer ' + user.api_token;
            commit('setUser', user);
          })
          .catch(error => {
            commit('setProcessing', false);
          });
      
    },
    signOut({ commit }) {
      localStorage.removeItem('user');
      commit('setLogout');
    }
  }
}
