//require('./bootstrap');

import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueI18n from 'vue-i18n';
import VueProgressBar from 'vue-progressbar';
import vuePerfectScrollbar from 'vue-perfect-scrollbar';
import VCalendar from 'v-calendar';
import vSelect from 'vue-select';
import Breadcrumb from './views/components/Common/Breadcrumb';
import Colxx from './views/components/Common/Colxx';
import StaffSelect from './views/components/Custom/StaffSelect';
import HireSelect from './views/components/Custom/HireSelect';
import FireSelect from './views/components/Custom/FireSelect';
import Vuetable from 'vuetable-2/src/components/Vuetable';
import VuetablePaginationBootstrap from './views/components/Common/VuetablePaginationBootstrap';
import contentmenu from 'v-contextmenu';
import moment from 'moment';
import _ from 'lodash';
import axios from 'axios';
import { apiUrl } from './constants/config';
import Notifications from './views/components/Common/Notification';
import VueTheMask from 'vue-the-mask';

import store from './store';
import router from './router';
import ru from '../lang/ru.json';
import uk from '../lang/uk.json';
import App from './views/App';

const locale = (localStorage.getItem('currentLanguage') && localeOptions.filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : 'uk';

Vue.use(BootstrapVue);
Vue.use(VueProgressBar, {
  color: '#117a8b',
  failedColor: '#e7523e',
});
Vue.use(VueI18n);
Vue.use(contentmenu);
Vue.use(Notifications);
Vue.component('vue-perfect-scrollbar', vuePerfectScrollbar);
Vue.component('piaf-breadcrumb', Breadcrumb);
Vue.component('b-colxx', Colxx);
Vue.component('v-select', vSelect);
Vue.component('staff-select', StaffSelect);
Vue.component('hire-select', HireSelect);
Vue.component('fire-select', FireSelect);
Vue.component('vuetable', Vuetable);
Vue.component('vuetable-pagination-bootstrap', VuetablePaginationBootstrap);
Vue.use(VueTheMask);
Vue.use(VCalendar, {
  firstDayOfWeek: 2,
  locale: locale,
  formats: {
    title: 'MMM YYYY',
    weekdays: 'WW',
    navMonths: 'MMM',
    input: ['L', 'YYYY-MM-DD', 'YYYY/MM/DD'],
    dayPopover: 'L',
  },
  datePickerShowDayPopover: false,
  popoverExpanded: true,
  popoverDirection: 'bottom'
});

const messages = { ru: ru, uk: uk };

const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: 'uk',
  messages,
  silentTranslationWarn: process.env.NODE_ENV === 'production'
});

Vue.filter('toDate', function(value) {
  if (value) {
    return moment(String(value), 'YYYY-MM-DD').toDate();
  }
});
Vue.filter('toDateString', function(value) {
  if (value) {
    return moment(value).format('YYYY-MM-DD');
  }
});
Vue.filter('toLocaleDateString', function(value) {
  if (value) {
    return moment(String(value), 'YYYY-MM-DD').format('DD.MM.YYYY');
  }
});
Vue.filter('toLocaleDateTimeString', function(value) {
  if (value) {
    return moment(String(value), 'YYYY-MM-DD H:mm:ss').format('DD.MM.YYYY H:mm:ss');
  }
});
Vue.filter('prettyBytes', function (num) {
  if (typeof num !== 'number' || isNaN(num)) {
    throw new TypeError('Expected a number');
  }

  var exponent;
  var unit;
  var neg = num < 0;
  var units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  if (neg) {
    num = -num;
  }

  if (num < 1) {
    return (neg ? '-' : '') + num + ' B';
  }

  exponent = Math.min(Math.floor(Math.log(num) / Math.log(1000)), units.length - 1);
  num = (num / Math.pow(1000, exponent)).toFixed(2) * 1;
  unit = units[exponent];

  return (neg ? '-' : '') + num + ' ' + unit;
  
});

Vue.prototype.$_ = _;

Vue.prototype.$http = axios.create({
  baseURL: apiUrl,
  headers: {'X-Requested-With': 'XMLHttpRequest'},
});

Vue.prototype.$enum = {};

export default new Vue({
    i18n,
    router,
    store,
    created() {

      // HTTP

      const $progress = this.$Progress;
      //const $store = this.$store;
      const $notify = this.$notify;

      var currentUser = localStorage.getItem('user') != null ? JSON.parse(localStorage.getItem('user')) : null;
              
      if (currentUser) {
        this.$http.defaults.headers.Authorization = 'Bearer ' + currentUser.api_token;
        this.$http.defaults.headers['API-TOKEN'] = currentUser.api_token;
      }
      
      this.$http.interceptors.request.use(
          function (config) {      
              $progress.start();              
              return config;        
          },
          function (error) {
              $progress.fail();        
              return Promise.reject(error);        
          }
      );

      this.$http.interceptors.response.use(
        function(response) {

          $progress.finish();

          if (response.data.message) {
            /*
            $store.commit('setAlert', {
                  type: 'info',
                  text: response.data.message
              });
            */

          $notify('info filled', '', response.data.message, {
              duration: 3000,
              permanent: false
          });

            
          } else {
            // $store.commit('setAlert', null);
          }

          return response;

        }, 
        function(error) {
    
            $progress.fail();

            if (error.response) {

              let text = error.message;
              if (error.response.data.errors) {
                  text = null;
                  for (var key in error.response.data.errors) {
                      if (text) {
                          text = text + '<br>' + error.response.data.errors[key][0];
                      } else {
                          text = error.response.data.errors[key][0];
                      }
                  }
              } else {
                  text = error.response.data.message;
              }
  
              /*
              $store.commit('setAlert', {
                  type: 'danger',
                  text: text
              });

              window.scrollTo(0, 0);
              */

              $notify('error filled', '', text, {
                  duration: 3000,
                  permanent: false
              });

            }
    
            return Promise.reject(error);

        }
      );

      // ENUM

      const $enum = this.$enum;

      this.$http
        .get('/enum/all')
        .then(response => {

          var enums  = response.data;

          _.forEach(enums, function(dir, key) {
              $enum[key] = [];
            _.forEach(dir.data, function(item) {
              $enum[key].push({code: item.id, label: item.name + (item.code && item.code.length ? ' (' + item.code + ')' : '')});
            });
          });

        });

    },
    render: h => h(App)
}).$mount('#app');