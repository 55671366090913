<template>
    <v-select v-model="selectVal" :options="options" :reduce="item => item.code">
        <slot name="no-options">{{$t('common.no_data')}}</slot>
    </v-select>
</template>

<script>
    export default {
        props: ['value'],
        data() {
            return {
                options: []           
            };
        },
        computed: {
            selectVal: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                }
            }
        },
        mounted() {
            let options = this.options;
            this.$http
                .get('/staff?per_page=all')
                .then(response => {
                    _.forEach(response.data.data, function(item) {
                        options.push({code: item.id, label: item.name});
                    });
                });
        },
        methods: {
  
        }
    }
</script>