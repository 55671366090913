const state = {
    alert: null
}

const getters = {
    getAlert(state) {
        return state.alert;
    },
}

const mutations = {
    setAlert(state, payload) {
        state.alert = payload
    }
}

const actions = {}

export default {
    state,
    getters,
    mutations,
    actions
}
  